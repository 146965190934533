<template>
    <div class="down">
        <div class="down-list" v-if="list.length">
            <div class="down-item" v-for="item in list" :key="item._id">
                <div class="down-item-title">{{ item.categoryName }}</div>
                <div class="down-docs" v-if="item.docs.length">
                    <div
                        class="down-docs-item click-op"
                        v-for="row in item.docs"
                        :key="row.name"
                        @click="handleClick(row)"
                    >
                        {{ row.name }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { downloadMinxin } from "../../mixin/download.js";
export default {
    mixins: [downloadMinxin],
    data() {
        return {
            list: []
        };
    },
    created() {
        this.fetchData();
    },

    methods: {
        handleClick(row) {
            let type = row.url.split(".").slice(-1)[0];
            this.download({ src: row.url, name: row.name, type });
        },
        async fetchData() {
            let res = await this.$http.get("/hall/docs");
            this.list = res;
        }
    }
};
</script>

<style lang="less" scoped>
.down {
    .down-list {
        .down-item {
            margin-bottom: 20px;
            .down-item-title {
                text-align: left;
                height: 24px;
                font-size: 18px;
                font-weight: 600;
                color: rgba(38, 38, 38, 1);
                line-height: 24px;
                margin-bottom: 20px;
            }
            .down-docs {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                .down-docs-item {
                    margin-bottom: 20px;
                    margin-right: 40px;
                    height: 32px;
                    line-height: 32px;
                    padding: 0 16px;
                    border-radius: 2px;
                    border: 1px solid rgba(0, 145, 255, 1);
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(0, 145, 255, 1);
                }
            }
        }
    }
}
</style>
